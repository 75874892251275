import axios from "axios";

const API = axios.create({
  baseURL: "/api/eval_bot",
});

//register
const createBot = async (botData, token) => {
  const response = await API.post("/create", botData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

//get_bot by creator
const getBotbyOrg = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.get(`/${id}`, config);
  return response.data;
};

//get combinations
const getCombinations = async (id, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.get(`/combinations/${id}`, config);
  return response.data.combinations;
};

//get combinations
const getgenerationCombinations = async (id, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.get(`/generation_combinations/${id}`, config);
  return response.data.combinations;
};

//get combinations
const getFinetuningCombinations = async (id, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.get(
    `/generation_combinations/finetune/${id}`,
    config
  );
  return response.data.combinations;
};

//run evals
const runEvals = async (ids, token) => {
  const { orgId, user_id, bot_id, combination_id } = ids;

  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.get(
    `/runeval/retrieval/${orgId}/${user_id}/${bot_id}/${combination_id}`,
    config
  );
  return response.data;
};

//run generation evals
const runGenerationEvals = async (ids, token) => {
  const { orgId, user_id, bot_id, combination_id } = ids;

  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.post(
    `/runeval/generation/${orgId}/${user_id}/${bot_id}/${combination_id}`,
    { retrieval_combination: ids.retrieval_combination },
    config
  );
  return response.data;
};

//run finetuning evals
const runFinetuningEvals = async (ids, token) => {
  const { orgId, user_id, bot_id, combination_id } = ids;

  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.get(
    `/runeval/generation/finetune/${orgId}/${user_id}/${bot_id}/${combination_id}`,
    config
  );
  return response.data;
};

//run start finetuning
const startFinetuning = async (ids, token) => {
  const { orgId, user_id, bot_id } = ids;

  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  console.log("IDS: ", orgId, user_id, bot_id, token);
  const response = await API.get(
    `/init/finetune/${orgId}/${user_id}/${bot_id}`,
    config
  );
  return response.data;
};

//get llm finetuning status
const getFinetuningStatus = async (ids, token) => {
  const { bot_id, combination_id } = ids;

  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await API.get(
    `/get/finetune/status/${bot_id}/${combination_id}`,
    config
  );
  return response.data;
};

//Generate prompts
const generatePrompts = async (bot_id, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.get(`/generate/mutations/${bot_id}`, config);
  localStorage.setItem("prompts", JSON.stringify(response.data));
  return response.data;
};

//Generate prompts
const generateQueryWithProblemStatement = async (data, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.post(
    `/generate/query_with_problems`,
    data,
    config
  );
  return response.data;
};

//run evals
const generateBasePrompt = async (problem_statement, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.get(`/generate/mutations/${""}`, config);
  localStorage.setItem("prompts", JSON.stringify(response.data));
  return response.data;
};

//run evals
const initializeCombinations = async (data, token) => {
  const { user_id, bot_id, orgId } = data;
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.get(`/init/${orgId}/${user_id}/${bot_id}`, config);
  return response.data;
};

//run evals
const initializeEmbeddingFinetuning = async (data, token) => {
  const { user_id, bot_id, orgId } = data;
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await API.get(
    `/init/finetune/${orgId}/${user_id}/${bot_id}`,
    config
  );
  return response.data;
};

//Initialize Mutations
const initializeMutations = async (data, token) => {
  const { bot_id } = data;
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.post(`/init/mutations/${bot_id}`, data, config);
  return response.data;
};

//Get Selected Combinations
const get_selected_combinations = async (bot_id, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.get(`/selectedCombinations/${bot_id}`, config);
  return response.data;
};

//Get Selected Combinations
const makeDefault = async (data, token) => {
  const { bot_id, combination_id } = data;
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.get(
    `/makedefault/${bot_id}/${combination_id}`,
    config
  );
  return response.data;
};

//delete chatbot
const deleteChatbot = async (id, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await API.delete(`/combinationset/${id}`, config);
  return response.data;
};

//upload sources
const uploadSources = async (data, token) => {
  const { orgId, user_id, bot_id } = data.ids;
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const response = await API.post(
    `/update/${orgId}/${user_id}/${bot_id}`,
    data.formData,
    config
  );
  return response.data;
};

//get data types
const getDataTypes = async (data, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  // Debugging FormData content to ensure it has the correct data
  // for (const [key, value] of data.entries()) {
  //   console.log(`${key}:`, value);
  // }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.post(`/datatypes`, data, config);
  return response.data;
};

//get data types
const getMetadataDescription = async (data, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.post(
    `/generate-metadata-description`,
    JSON.stringify(data),
    config
  );
  return response.data;
};

//get default combinations
const getDefaultCombinations = async (data, token) => {
  const { user_id, bot_id } = data;
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.post(
    `/getdefualt/${user_id}/${bot_id}`,
    data,
    config
  );
  return response.data;
};

//save default combinations
const saveDefaultCombinations = async (data, token) => {
  const { user_id, bot_id } = data;
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const default_dict = data.default_dict;
  const response = await API.post(
    `/savedefault/${bot_id}`,
    default_dict,
    config
  );
  return response.data;
};

//get finetuning training loss
const getFinetuningTrainingLoss = async (data, token) => {
  const { org_id, bot_id, combination_id } = data;
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.get(
    `/finetune/training_loss/${org_id}/${bot_id}/${combination_id}`,
    config
  );
  return response.data;
};

//cancel finetuning training loss
const cancelFinetuning = async (data, token) => {
  const { orgId, bot_id, combination_id } = data;
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.get(
    `/cancel_finetuning/${orgId}/${bot_id}/${combination_id}`,
    config
  );
  return response.data;
};

//update bot name
const updateAppName = async (data, token) => {
  const { orgId, bot_id } = data;
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.post(
    `/edit/chatbot_name/${orgId}/${bot_id}`,
    data,
    config
  );
  return response.data;
};

//update bot name
const updateGoldenResponses = async (data, token) => {
  const { orgId, bot_id } = data;
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.post(
    `/update/goldenResponses/${orgId}/${bot_id}`,
    data,
    config
  );
  return response.data;
};

//update bot name
const updatePromptStatementSettings = async (data, token) => {
  const { orgId, bot_id } = data;
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.post(
    `/update/promptStatementSettings/${orgId}/${bot_id}`,
    data,
    config
  );
  return response.data;
};

const chatbotService = {
  createBot,
  getBotbyOrg,
  getCombinations,
  getgenerationCombinations,
  getFinetuningCombinations,
  runEvals,
  runGenerationEvals,
  runFinetuningEvals,
  startFinetuning,
  getFinetuningStatus,
  deleteChatbot,
  initializeCombinations,
  generatePrompts,
  generateQueryWithProblemStatement,
  initializeMutations,
  get_selected_combinations,
  makeDefault,
  uploadSources,
  getDataTypes,
  getMetadataDescription,
  initializeEmbeddingFinetuning,
  getDefaultCombinations,
  saveDefaultCombinations,
  getFinetuningTrainingLoss,
  cancelFinetuning,
  updateAppName,
  updateGoldenResponses,
  updatePromptStatementSettings,
};

export default chatbotService;
